import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField,
    RadioGroup,Radio,FormControlLabel,FormLabel } from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";
import axios from 'axios';
//import io from "socket.io-client";

//const ENDPOINT = "https://seguridad-socket.daduva.mx:5030";

const useStyles = makeStyles((theme) => ({}));
const source = axios.CancelToken.source();    
const ModalNvo = ({abrirModal, setAbrirModal,  buscarDatos, setImgURL,listEdo,setTituloQR }) => {    
    const classes = useStyles();    
    const history = useHistory();
    const idusu = localStorage.getItem('UsuId');
    //const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta        
    const [listMun, setListMun] = useState([])
    const [listResol, setListResol] = useState([])
    const [listBanda, setListBanda] = useState([])
    const [listOperador, setListOperador] = useState([])
    const [listTecno, setListTecno] = useState([])
    const [listZona, setListZona] = useState([])
    const [nuevoRegistro, setNuevoRegistro] = useState({
        Estado: '', Municipio: '',          
        Ine:"", Folio:"",
        Asiento:"", 
        Acceso:"",       
Status:1,
Resol:[],
Lat:0,
Lon:0,
Avgrsrp:0,
Avgrsrq:0,
Sitel:0,
Chanel:0,
Banda:[],
Opera:[],
Tecno:[],
Zona:[],
Alias:"",
Dir:"",
    });    

    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [Advertencia, setAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);    
    const {Estado, Municipio,  Ine,
        Folio, Asiento,  Acceso,Resol,
        Lat,Lon, Avgrsrp, Avgrsrq, Sitel, Chanel, Banda, Opera, Tecno,
        Zona, Alias, Dir} = nuevoRegistro
    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {     

        getResol()
        getBanda()
        getOperador()
        getTecnologia()
        getZona()
    }, []);

  /*   const envioSocket = (auxID) => {
        //console.log("antes de emit");        
        //e.preventDefault()
        socket.emit(
          "RegistroNew",
          JSON.stringify({
            origen:"https://seguridad-admin.daduva.mx/api/evento-registro-new",
            Event:Evento,
            Avance:auxID,
            fecha:moment(new Date).format("YYYY-MM-DDTHH:mm:ss")             
          })
        );
       
    };
 */
    
    const getResol = () => {
        const data = qs.stringify({            
            idDispositivo: auxDispV
        });
        const url = "hexagono-resolucion-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListResol(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getBanda = () => {
        const data = qs.stringify({            
            idDispositivo: auxDispV
        });
        const url = "antena-banda-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListBanda(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getOperador = () => {
        const data = qs.stringify({            
            idDispositivo: auxDispV
        });
        const url = "antena-operador-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListOperador(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getTecnologia = () => {
        const data = qs.stringify({            
            idDispositivo: auxDispV
        });
        const url = "antena-tecnologia-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListTecno(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getZona = () => {
        const data = qs.stringify({            
            idDispositivo: auxDispV
        });
        const url = "antena-zona-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListZona(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const recuperarMunicipios = (auxEdo) => {
        setListMun([])
        let data = qs.stringify({  
            estado: auxEdo,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        let url = "lugar-municipio";

        function respuesta(response) {
            if (response[0].Id != -1) {
                let nvoArr=[]
                response.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                    }
                }); 
                setListMun(nvoArr)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarEdo = (e) => {

        if (e !== null && e !== undefined) {                        
            recuperarMunicipios(e.Id)          
            setNuevoRegistro({
                ...nuevoRegistro,Estado: e,

                Municipio: "", 
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,Estado: "",
                Municipio: "",
            })
      
        }
    }

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {
    
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: e, 
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: "", 
            })
    
        }
    }

    const onChange = e => {
        setNuevoRegistro({
            ...nuevoRegistro,
            [e.target.name]: e.target.value
        })
    }

    const onChangeTxt = e =>
	{  
        const expNopermitida = new RegExp('[%{}*|`0-9.$@#_&-+()/:;!?,<>=]');        
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};

    const onChangeNum = e =>
	{  
        const expNopermitida = new RegExp('[A-Za-z%{}*|`.$@#_&-+()/:;!?,<>=]');        
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};

    const onChangeTxt2 = e =>
	{  
        const expNopermitida = new RegExp('[%{}*|`.$@#_&-+()/:;!?,<>=]');        
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};

    const guardarProspecto = () => {        
       // setBotonDeshabilitado(true);
        const hoy=moment();        
        const data = qs.stringify({                         
            Muni: Municipio.Id,                                                         
            idDispositivo: auxDispV                              
        });

        const url = "evento-registro-new";

        function respuesta(response) {
            if (response.respuesta  === 1) {
                setAviso(true)
                toast.success('Registro correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-registro${response.mensaje}`
                })
                buscarDatos()
                setImgURL(response.url)
                setTituloQR(``)
                /*  setTimeout(() => {                    
                    setModalImg(true)
                  }, 500);  
               setTimeout(() => {
                    envioSocket(response.mensaje)
                }, 200);  */             
                setAbrirModal(false)
            }
            else 
            {
                if (response.Mensaje === 0) {
                    toast.error(`ya esta registrado al Evento ` , {
                        pauseOnFocusLoss: false,
                        toastId: `error-prospecto${2}`
                    })
                }
                else{
                    toast.error('Datos incorrectos, intente mas tarde', {
                        pauseOnFocusLoss: false,
                        toastId: `error-prospecto${1}`
                    })
                }                
                
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
           // console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    function guardarDatos(e) {
        e.preventDefault()
        if (  !verificaNulos(Estado,"Estado")  && !verificaNulos(Municipio,"Municipio")                          
          //  && ((!verificaNulos(Ine,"Clave de Elector") && Ine.length===18 )|| !verificaNulos("","INE"))                      
        )            
        {   //console.log("prueba 2");
            setAviso(false);                        
            guardarProspecto()
            
        }  
    }
    
    const onChangeGen = e =>
    {  
        setNuevoRegistro({ ...nuevoRegistro,          
            genero: e.target.value  
        })        
    };

    return (
    <Modal
        titulo={'Nuevo Registro'} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={Advertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={5}>             
                {listEdo.length !== 0 ?<Autocomplete
                    id="edo-select-01" size='small' fullWidth //disabled
                    onChange={(e, option) => guardarEdo(option)} value={Estado}
                    getOptionLabel={(option) => option.Nom} name="Estado"
                    noOptionsText={"Sin coincidencias"} options={listEdo}
                    renderInput={(params) => (
                        <TextField {...params} size='small'
                            label="Estado" variant="outlined"/>
                    )}
                />
                : <LinearProgress/>}
            </Grid>
            <Grid item xs={7}>
                {listMun.length !== 0 ?
                    <Autocomplete
                        id="muni-select-nvo" size='small' fullWidth
                        onChange={(e, option) => guardarMuni(option)} value={Municipio}
                        getOptionLabel={(option) => option.Nom} name="Municipio"
                        noOptionsText={"Sin coincidencias"} options={listMun}
                        renderInput={(params) => (
                            <TextField {...params} size='small'
                                label="Municipio" variant="outlined"/>
                        )}
                    />
                    : Estado.length===0 ? null : <LinearProgress/>}
            </Grid>
            
            
            {listResol.length!=0 ?       
            <FormControl style={{width:"10rem", marginRight:".5rem"}}>
                <InputLabel id="Resol-inpt">Resolución</InputLabel>
                <Select
                    label="Resolución" id="resol-selc" name="Resol"
                    value={Resol} onChange={onChange}
                >
                    {listResol.map((tp, index) => (
                    <MenuItem value={tp} key={tp.Id}>
                        <em>{tp.Nom}</em>
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
                :null}
            {listBanda.length!=0 ?       
            <FormControl style={{width:"10rem", marginRight:".5rem"}}>
                <InputLabel id="Banda-inpt">Banda</InputLabel>
                <Select
                    label="Banda" id="Banda-selc" name="Banda"
                    value={Banda} onChange={onChange}
                >
                    {listBanda.map((tp, index) => (
                    <MenuItem value={tp} key={tp.Id}>
                        <em>{tp.Nom}</em>
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            :null}
            {listOperador.length!=0 ?       
            <FormControl style={{width:"10rem", marginRight:".5rem"}}>
                <InputLabel id="Operador-inpt">Operador</InputLabel>
                <Select
                    label="Operador" id="Operador-selc" name="Opera"
                    value={Opera} onChange={onChange}
                >
                    {listOperador.map((tp, index) => (
                    <MenuItem value={tp} key={tp.Id}>
                        <em>{tp.Nom}</em>
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            :null}
            {listTecno.length!=0 ?       
            <FormControl style={{width:"10rem", marginRight:".5rem"}}>
                <InputLabel id="Tecno-inpt">Tecnologia</InputLabel>
                <Select
                    label="Tecnologia" id="Tecno-selc" name="Tecno"
                    value={Tecno} onChange={onChange}
                >
                    {listTecno.map((tp, index) => (
                    <MenuItem value={tp} key={tp.Id}>
                        <em>{tp.Nom}</em>
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            :null}
            {listZona.length!=0 ?       
            <FormControl style={{width:"10rem", marginRight:".5rem"}}>
                <InputLabel id="Zona-inpt">Zona</InputLabel>
                <Select
                    label="Zona" id="Zona-selc" name="Zona"
                    value={Zona} onChange={onChange}
                >
                    {listZona.map((tp, index) => (
                    <MenuItem value={tp} key={tp.Id}>
                        <em>{tp.Nom}</em>
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            :null}
    
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="tel-text" name="Ine" label="Teléfono" fullWidth size="small"
                    value={Ine} onChange={onChangeNum} variant="outlined" inputProps= {{ maxlength: 10 }} />
            </Grid>
            <Grid item xs={8} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Folio-text" name="Folio" label="Folio" fullWidth size="small"
                    value={Folio} onChange={onChangeTxt2} variant="outlined" inputProps= {{ maxlength: 50 }} />
            </Grid>            
    
            <Grid item xs={3} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Asiento-text" name="Asiento" label="Asiento" fullWidth size="small"
                    value={Asiento} onChange={onChangeTxt2} variant="outlined" inputProps= {{ maxlength: 5 }}/>
            </Grid>
        
            <Grid item xs={6} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Acceso-text" name="Acceso" label="Acceso" fullWidth size="small"
                    value={Acceso} onChange={onChangeTxt2} variant="outlined"/>
            </Grid>
           
            <Grid item xs={5}>
           
            </Grid>
        </Grid>
    </Modal>);
};

export default ModalNvo;
