import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField,
    RadioGroup,Radio,FormControlLabel,FormLabel } from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({}));
const source = axios.CancelToken.source();    
const ModalNvoEvento = ({abrirModal, setAbrirModal,  buscarDatos,  }) => {
    const fechaMin = moment(new Date())    
    const classes = useStyles();    
    const history = useHistory();
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const idusu = localStorage.getItem('UsuId');
    const [fSelect, setFSelect] = useState({FInicio:fechaMin,FFinal:fechaMin});
    const [listMun, setListMun] = useState([])    
    const [nuevoRegistro, setNuevoRegistro] = useState({
        Estado: '', Municipio: '',
        Evento: '', Fecha: '',              
        Capacidad:"",
        Boleto:"", Lugar:"",             
        Direccion: '',
        Descr: '',Observ: '',llave:0
    });        
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [Advertencia, setAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);
    const [listEdo,setListEdo] = useState([])
    const{FInicio,FFinal}=fSelect
    const {Estado, Municipio, Evento,Descr,Observ,llave,Direccion,
       Capacidad, Boleto, Lugar,} = nuevoRegistro
    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {
        const llamadaListEdo = () => {
            const data = qs.stringify({
                usuario: idusu,  idDispositivo: auxDispV,
            });
            const url = "lugar-estado";
    
            function respuesta(auxiliar) {
                if (auxiliar[0].Id != -1) {       
                    let nvoArr=[]
                    auxiliar.forEach(element => {
                        if (element.Id!==0) {
                            nvoArr.push(element)
                        }
                    });               
                    return setListEdo(nvoArr)
                }
            }    
            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
        }

        if (listEdo.length === 0) {
            llamadaListEdo()
        }

    }, []);

    const recuperarMunicipios = (auxEdo) => {
        setListMun([])
        let data = qs.stringify({    
            estado: auxEdo,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        let url = "lugar-municipio";

        function respuesta(response) {
            if (response[0].Id != -1) {
                let nvoArr=[]
                response.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                    }
                }); 
                setListMun(nvoArr)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarEdo = (e) => {

        if (e !== null && e !== undefined) {                        
            recuperarMunicipios(e.Id)          
            setNuevoRegistro({
                ...nuevoRegistro,Estado: e,

                Municipio: "", 
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,Estado: "",
                Municipio: "",
            })
      
        }
    }

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {
    
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: e, 
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: "", 
            })
    
        }
    }

    const onChange = e => {
        setNuevoRegistro({
            ...nuevoRegistro,
            [e.target.name]: e.target.value
        })
    }

    const onChangeNum = e =>
	{  
        const expNopermitida = new RegExp('[A-Za-z%{}*|`.$@#_&-+()/:;!?,<>=]');        
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};

    const onChangeTxt2 = e =>
	{  
        const expNopermitida = new RegExp('[%{}*|`.$@#_&-+()/:;!?,<>=]');        
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};

    const guardarProspecto = () => {        
       // setBotonDeshabilitado(true);
        const data = qs.stringify({
            Llave:llave,                             
            Muni: Municipio.Id,      
            Usuario: idusu,
            Status:1,                                                
            Fini:`${moment.utc(FInicio).format("YYYY-MM-DDTHH:mm")}:00`, //+ 'T00:00:00',            
            Ffin:`${moment.utc(FFinal).format("YYYY-MM-DDTHH:mm")}:59`, //+ 'T23:59:59', 
            Descr:Descr,           
            Obsrv:Observ,   
            Capacidad:Capacidad,
            Direcc:Direccion,
            Boleto:Boleto,
            Lugar:Lugar,                                         
            idDispositivo: auxDispV                    
        });

        const url = "evento-new-edit";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Evento agregado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-evento${1}`
                })
                setAbrirModal(false)
                buscarDatos()
            } else {
              //  setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-evento${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;                  
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
           // console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }


    function guardarDatos(e) {
        e.preventDefault()
        if (   !verificaNulos(Estado,"Estado")  
            && !verificaNulos(Municipio,"Municipio")  
            && !verificaNulos(Boleto,"Boleto")  
            && ((!verificaNulos(Descr,"Descripción") && Descr.length>=3 )|| !verificaNulos("","Descripción"))
            && ((!verificaNulos(Lugar,"Lugar") && Lugar.length>=3 )|| !verificaNulos("","Lugar"))
            && ((!verificaNulos(Capacidad,"Capacidad") && Capacidad.length>=1 )|| !verificaNulos("","Capacidad"))
            && ((!verificaNulos(Direccion,"Dirección") && Direccion.length>=3 )|| !verificaNulos("","Dirección"))
            && ((!verificaNulos(Observ,"Observación") && Observ.length>=3 )|| !verificaNulos("","Observación"))
        )            
        {   
            setAviso(false);
            console.log(`${moment.utc(FInicio).format("YYYY-MM-DDTHH:mm")}:00`, 
            `${moment.utc(FFinal).format("YYYY-MM-DDTHH:mm")}:59`);
            guardarProspecto()            
        }  
    }
    
    const onChangeGen = e =>
    {  
        setNuevoRegistro({ ...nuevoRegistro,          
            Boleto: e.target.value  
        })        
    };

    const onChangeFch = e =>
    {   const inicio=moment(e).format("YYYY-MM-DDTHH:mm")            
        const fin=moment(FFinal).format("YYYY-MM-DDTHH:mm")
        if (fin>inicio) {
            setFSelect( {...fSelect, FInicio: e } )    
        }
        
    };

    return (
    <Modal
        titulo={'Nuevo Evento'} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={Advertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={5}>             
                {listEdo.length !== 0 ?<Autocomplete
                    id="edo-select-01" size='small' fullWidth //disabled
                    onChange={(e, option) => guardarEdo(option)} value={Estado}
                    getOptionLabel={(option) => option.Nom} name="Estado"
                    noOptionsText={"Sin coincidencias"} options={listEdo}
                    renderInput={(params) => (
                        <TextField {...params} size='small'
                            label="Estado" variant="outlined"/>
                    )}
                />
                : <LinearProgress/>}
            </Grid>
            <Grid item xs={7}>
                {listMun.length !== 0 ?
                    <Autocomplete
                        id="muni-select-nvo" size='small' fullWidth
                        onChange={(e, option) => guardarMuni(option)} value={Municipio}
                        getOptionLabel={(option) => option.Nom} name="Municipio"
                        noOptionsText={"Sin coincidencias"} options={listMun}
                        renderInput={(params) => (
                            <TextField {...params} size='small'
                                label="Municipio" variant="outlined"/>
                        )}
                    />
                    : Estado.length===0 ? null : <LinearProgress/>}
            </Grid>           
            <Grid item xs={7} direction="row">
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DateTimePicker
                        label="Fecha Inicio" size={"small"} ampm={false}
                        value={FInicio} style={{width: 9 + 'rem',marginRight:".5rem"}}
                        onChange={onChangeFch}
                        minDate={fechaMin} maxDate={FFinal}
                        format="dd/MM/yyyy HH:mm"                                                                
                    />
                    <DateTimePicker
                        label="Fecha Fin" size={"small"} ampm={false}
                        value={FFinal} style={{width: 9 + 'rem'}}
                        onChange={(e)=> setFSelect( {...fSelect, FFinal: e } )}
                        minDate={fechaMin} //maxDate={FFinal}
                        format="dd/MM/yyyy HH:mm"                                                                
                    />
                   {/*  <DatePicker
                        label="Fecha Inicio" value={FInicio} 
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate={fechaMin} maxDate={FFinal}                        
                        onChange={ (e)=> setFSelect( {...fSelect, FInicio: e } ) }
                        style={{width: 8 + 'rem',marginRight:".5rem"}}
                    />
                     <DatePicker
                        label="Fecha Fin" minDate={fechaMin}
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                                        
                        onChange={ (e)=> setFSelect( { ...fSelect,FFinal: e} ) }
                        style={{width: 8 + 'rem'}} value={FFinal}
                    /> */}
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={5}>
            <FormControl component="fieldset" style={{marginLeft:.5+'rem'}}>          
                <FormLabel  htmlFor="Boleto-form">Boleto</FormLabel>        
                <RadioGroup  row aria-label="position"  defaultValue="top"
                    name="Boleto" value={Boleto} onChange={onChangeGen}
                >
                    <FormControlLabel value='1'  
                        control={<Radio color="primary" />} label="Si" />    
                    <FormControlLabel value='0'   
                        control={<Radio color="primary" />} label="No" />                         
                </RadioGroup>                     
            </FormControl>      
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="Descripcion-txt" name="Descr"
                    label="Descripción" variant="outlined"
                    multiline fullWidth onChange={onChangeTxt2}
                    maxRows={4} value={Descr} size="small"
                />
            </Grid>
            <Grid item xs={9} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Lugar-txt" name="Lugar" label="Lugar" fullWidth size="small"
                    value={Lugar} onChange={onChangeTxt2} variant="outlined"                    
                    //multiline  maxRows={2}
                />
            </Grid>
            
                 
            <Grid item xs={3} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="Capacidad-txt" name="Capacidad" label="Capacidad" fullWidth size="small"
                    value={Capacidad} onChange={onChangeNum} variant="outlined" inputProps= {{ maxlength: 5 }} />                
            </Grid>       
            <Grid item xs={12} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Direccion-txt" name="Direccion" label="Direccion" fullWidth size="small"
                    value={Direccion} onChange={onChangeTxt2} variant="outlined"
                    maxRows={4} multiline
                />                
            </Grid>
            
            <Grid item xs={12}>
                <TextField size="small"
                    id="Observacion-txt" name="Observ"
                    label="Observación" variant="outlined"
                    multiline fullWidth onChange={onChangeTxt2}
                    maxRows={4} value={Observ}
                />
            </Grid>
            <Grid item xs={5}>
           
            </Grid>
        </Grid>
    </Modal>);
};

export default ModalNvoEvento;
