
import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalInformativo";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import { Button, Box, Tooltip } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles((theme) => ({}));
const source = axios.CancelToken.source();    
const ModalImg = ({abrirModal, setAbrirModal,  imgURL,titulo }) => {  
    const classes = useStyles();    
    const history = useHistory();
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");

    useEffect(() => {
 
       
    }, []);

    const descargar=(url)=>{
        //console.log("antes de crear el link");
        let source =url
        let a = document.createElement('a');
        //a.download = true;
        //a.target = '_blank';
        a.href= source;  
        a.download = `${titulo} QR.png` ;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);      
        //window.open(source)
    } 

    return (
    <Modal  
        titulo={titulo} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} 
        tamanio={'sm'} maxTam={false}
     
    >
         <Box display={"flex"} flexDirection={"column"} justifyContent="center">
            <Box display={"flex"} justifyContent="center">
                <img src={imgURL} />
            </Box>
            <Box  display={"flex"} justifyContent="center" mt={2}>
                <Button onClick={()=>descargar(imgURL)} variant="contained"  color="primary">DESCARGAR QR</Button>
            </Box>
            
         </Box>
        
        
    </Modal>);
};

export default ModalImg;
