import React,{useState,useContext} from 'react'
import {TextField,FormControl,InputLabel,Select,LinearProgress,
    MenuItem,Tooltip,Box, Paper,IconButton} from "@material-ui/core";
//import {ExpandLess,ExpandMore  } from '@material-ui/icons';
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import MapaAlerta from './MapaUnidSeg'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
//import io from "socket.io-client";
import { authUser } from '../funciones/AuthUser'
//import TablaVirtual from './TablaVirtual';
import { grey } from '@material-ui/core/colors';
//import { format } from 'date-fns'
import { llamaApiCargaToken, ErrorGeneral } from '../funciones/ClienteAxios';
import SearchIcon from '@material-ui/icons/Search';
//const ENDPOINT = "https://sockets.daduva.mx:5000";

const useStyles = makeStyles((theme) => ({
    root: {
    //width: '100%',
      //maxWidth: 360,                  
      backgroundColor: theme.palette.background.paper,
      
    },
    miPaper:{
        backgroundColor: grey [700],
    },
    nested: {
      paddingLeft: theme.spacing(4),      
    },

  }));
const MonitUnidSeg = () => {
    const source = axios.CancelToken.source();    
    let history = useHistory();   
    const altuTamMax =800
    const classes = useStyles();
    const libreriaG=[process.env.REACT_APP_GOOGLE_LIBRARIE]
    const usu = localStorage.getItem('UsuarioNom');     
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo')):0;                   
    const auxDispV = localStorage.getItem("Dispvo") && localStorage.getItem("Dispvo").length >= 12 ? localStorage.getItem("Dispvo") : "451224452121";
    const idusu = localStorage.getItem('UsuId');
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext); 
    const[listHexa,setListHexa]=useState([])
    const[listRango,setListRango]=useState([])
    const [cargando, setCargando] = useState(false);    
    const [listMun, setListMun] = useState([])
    const [listEdo,setListEdo] = useState([])
    const [listResol, setListResol] = useState([])
    const [filtros, setFiltros] = useState({ Estado: "", Municipio: "",Resol:[]});    
    const {Estado, Municipio,Resol} = filtros
    
    
    React.useEffect(()=>{        
        veriSesion()       
        return () => {
            source.cancel();
        }
    },[])    

    const veriSesion =  () => {            
        let auxValidacion=authUser()
        if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
            ||(idusu===undefined||idusu===''||idusu===null) )
        {   guardarUsuario([])				
            guardarGenerales([])    
            localStorage.clear();            	             
            history.push("/")	                	
        }
        else
        {   if (usuario.length==0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                             
            }                                        
            guardarGenerales({...generales,Titulo:'Mapa'})                                                             
            getListEdo()
            getResol()
        }   
    }

    const getResol = () => {
        const data = qs.stringify({            
            idDispositivo: auxDispV
        });
        const url = "hexagono-resolucion-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListResol(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };
    
    const getListHexa = () => {
        const data = qs.stringify({
            Usuario: idusu, Resol:Resol?.Valor,
            Muni:Municipio?.Id, idDispositivo: auxDispV,
        });
        const url = "hexagono-muni-list";
        const url2 = "hexagono-rango-list";
        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {   
                let nvoArr=[]
                auxiliar.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                     //   console.log(nvoArr);
                    }
                });         
                return setListHexa(nvoArr)
            }
        }    
       
        function respuesta2(auxiliar) {
            if (auxiliar[0].Id != -1) {   
                let nvoArr=[]
                auxiliar.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                     //   console.log(nvoArr);
                    }
                });         
                return setListRango(nvoArr)
            }
        }    

        if (Municipio!=="" &&Resol.length!==0 ) {
            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
            llamaApiCargaToken(data, url2, respuesta2, ErrorGeneral, setCargando, history, source);
        }
    }
    

    const getListEdo = () => {
        const data = qs.stringify({
            usuario: idusu,  idDispositivo: auxDispV,
        });
        const url = "lugar-estado";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {   
                let nvoArr=[]
                auxiliar.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                    }
                });         
                return setListEdo(nvoArr)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const getListMuni = (auxEdo) => {
        setListMun([])
        const data = qs.stringify({  
            estado: auxEdo,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar-municipio";

        function respuesta(response) {
            if (response[0].Id != -1) {
                let nvoArr=[]
                response.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                    }
                }); 
                setListMun(nvoArr)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarEdo = (e) => {
        if (e !== null && e !== undefined) {                        
            getListMuni(e.Id)          
            setFiltros({ ...filtros,Estado: e, Municipio: "", })
        } else {
            setFiltros({ ...filtros,Estado: "", Municipio: "",})      
        }
    }

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {    
            //getListAntena(e.Id)
            setFiltros({ ...filtros, Municipio: e, })
        } 
        else 
        {   setFiltros({ ...filtros, Municipio: "", })    
        }
    }

    const onChange = e => {
        setFiltros({ ...filtros, [e.target.name]: e.target.value })
    }

    const campoEdo=  listEdo.length !== 0 ?
        <Autocomplete
            id="edo-select-01" size='small' fullWidth //disabled
            onChange={(e, option) => guardarEdo(option)} value={Estado}
            getOptionLabel={(option) => option.Nom} name="Estado"
            noOptionsText={"Sin coincidencias"} options={listEdo}
            renderInput={(params) => (
                <TextField {...params} size='small'
                    label="Estado" variant="outlined"/>
            )}
        />
    : <LinearProgress/>

    const campoMuni= listMun.length !== 0 ?
        <Autocomplete
            id="muni-select-nvo" size='small' fullWidth
            onChange={(e, option) => guardarMuni(option)} value={Municipio}
            getOptionLabel={(option) => option.Nom} name="Municipio"
            noOptionsText={"Sin coincidencias"} options={listMun}
            renderInput={(params) => (
                <TextField {...params} size='small'
                    label="Municipio" variant="outlined"/>
            )}
        />
    : Estado.length===0 ? null : <LinearProgress/>

    
    const   tamanioH= (window.innerHeight<altuTamMax ? window.innerHeight-100: window.innerHeight - 100)
    return ( 
    <Box width={100+'%'} display="flex">
                
        <Paper style={{height:tamanioH  }}>
        <Box width={15+'rem'}   style={{marginRight:1+'rem',minWidth:13+'rem', height:tamanioH }}>
         {/*    <IntervaloSelect fechasSeleccionadas={fechasSeleccionadas} 
                setFechasSeleccionadas={setFechasSeleccionadas} 
            /> */}
            <Box mt={2} mb={2} ml={1}>
            {listResol.length!=0 ?       
                <FormControl style={{width:"14rem"}}>
                <InputLabel id="Resol-inpt">Resolución</InputLabel>
                <Select
                    label="Resolución" id="resol-selc" name="Resol"
                    value={Resol} onChange={onChange}
                >
                    {listResol.map((tp, index) => (
                    <MenuItem value={tp} key={tp.Id}>
                        <em>{tp.Valor}</em>
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            :null}
            </Box>
            <Box mt={2} mb={2} ml={1}>
                {campoEdo}
            </Box>
            <Box mb={2} ml={1}>
                {campoMuni}
            </Box>
            <Box style={{marginLeft:.5+'rem',display:"flex",justifyContent:"center"}}>
            <Tooltip title="Buscar">
                <IconButton color="primary"  aria-label="add circle" 
                    component="span"  onClick={()=>getListHexa()}  size="small"                    
                    disabled={Municipio!=="" && Resol.length!==0 ? false : true}
                >
                    <SearchIcon/> 
                </IconButton>    
            </Tooltip>      
            </Box>
           
        </Box>
        </Paper>
    
        {  listHexa.length!==0 && listRango.length!==0?
            <MapaAlerta  
                tamanio={{height: tamanioH, width: "100%"}}             
                libreriaG={libreriaG}   listHexa={listHexa}
                listRango={listRango}
            />
        :<Box mt={5} ><LinearProgress/></Box>   }
    </Box>
    )
}

export default MonitUnidSeg