import React,{useState,useContext} from 'react';
import {Grid,TextField,Link, Box,  LinearProgress, Button } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import { llamaApiCargaToken, ErrorGeneral } from '../funciones/ClienteAxios';
import ModalNvoReg from './ModalNvo';
import ModalQR from './ModalQr';
import ModalNvoEvento from './ModalNvoEvento';
import TablaRegistro from './TablaRegistro';

const useStyles = makeStyles({
    textoSelect: {
      '&:hover': {
        color:'#9ccdfa',
      },
      fontSize:1.25+'rem'
    },
});

const Principal = () => {
    const history = useHistory();     
    const classes = useStyles();    
    const usu = localStorage.getItem('UsuarioNom');                
    const idusu = localStorage.getItem('UsuId');
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo')):0;    
    const auxDispV = localStorage.getItem("Dispvo") && localStorage.getItem("Dispvo").length >= 12 ? localStorage.getItem("Dispvo") : "451224452121";
    const source = axios.CancelToken.source();    
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);     
    const[opcion,setOpcion]= useState(2)
    const [espera,setEspera]= useState(false);
    const [modalNvo,setModalNvo]= useState(false);
    const [modalNvoEvento,setModalNvoEvento]= useState(false);
    const [modalImg,setModalImg]= useState(false);
    const [imgURL,setImgURL]= useState("");
    const [titulo,setTitulo]= useState("");
    const [registro, setRegistro]= useState([]);
    const [listEvento, setListEvento] = useState([])
    const [cargando, setCargando] = useState(false);
    const [actualizar, setActualizar] = useState(false);    
    const [listMun, setListMun] = useState([])
    const [listEdo,setListEdo] = useState([])
    const [nuevoRegistro, setNuevoRegistro] = useState({ Estado: '', Municipio: '',});    
    const {Estado, Municipio} = nuevoRegistro
    React.useEffect(()=>{          
       
        veriSesion()       
        return () => {
            source.cancel();
        }
    },[actualizar])
        
    const veriSesion =  () => {            
        let auxValidacion=authUser()
        if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
            ||(idusu===undefined||idusu===''||idusu===null) )
        {   guardarUsuario([])				
            guardarGenerales([])    
            localStorage.clear();            	             
            history.push("/")	                	
        }
        else
        {   if (usuario.length==0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                             
            }                                        
            guardarGenerales({...generales,Titulo:'Inicio'})                                                             
            getListEdo()
        }   
    }
    
   /*  const getListAntena = (muni) => {
        const data = qs.stringify({
            Muni: muni,
            idDispositivo: auxDispV
        });
        const url = "antena-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListEvento(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }; */

    const getListEdo = () => {
        const data = qs.stringify({
            usuario: idusu,  idDispositivo: auxDispV,
        });
        const url = "lugar-estado";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {   
                let nvoArr=[]
                auxiliar.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                    }
                });         
                return setListEdo(nvoArr)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const getListMuni = (auxEdo) => {
        setListMun([])
        const data = qs.stringify({  
            estado: auxEdo,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar-municipio";

        function respuesta(response) {
            if (response[0].Id != -1) {
                let nvoArr=[]
                response.forEach(element => {
                    if (element.Id!==0) {
                        nvoArr.push(element)
                    }
                }); 
                setListMun(nvoArr)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarEdo = (e) => {
        if (e !== null && e !== undefined) {                        
            getListMuni(e.Id)          
            setNuevoRegistro({ ...nuevoRegistro,Estado: e, Municipio: "", })
        } else {
            setNuevoRegistro({ ...nuevoRegistro,Estado: "", Municipio: "",})      
        }
    }

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {    
            //getListAntena(e.Id)
            setNuevoRegistro({ ...nuevoRegistro, Municipio: e, })
        } 
        else 
        {   setNuevoRegistro({ ...nuevoRegistro, Municipio: "", })    
        }
    }

    const campoEdo=  listEdo.length !== 0 ?
        <Autocomplete
            id="edo-select-01" size='small' fullWidth //disabled
            onChange={(e, option) => guardarEdo(option)} value={Estado}
            getOptionLabel={(option) => option.Nom} name="Estado"
            noOptionsText={"Sin coincidencias"} options={listEdo}
            renderInput={(params) => (
                <TextField {...params} size='small'
                    label="Estado" variant="outlined"/>
            )}
        />
    : <LinearProgress/>

    const campoMuni= listMun.length !== 0 ?
        <Autocomplete
            id="muni-select-nvo" size='small' fullWidth
            onChange={(e, option) => guardarMuni(option)} value={Municipio}
            getOptionLabel={(option) => option.Nom} name="Municipio"
            noOptionsText={"Sin coincidencias"} options={listMun}
            renderInput={(params) => (
                <TextField {...params} size='small'
                    label="Municipio" variant="outlined"/>
            )}
        />
    : Estado.length===0 ? null : <LinearProgress/>

    const modNvo = modalNvo ?  <ModalNvoReg abrirModal={modalNvo} setAbrirModal={setModalNvo} setTituloQR={setTitulo}                        
                                    buscarDatos={()=>{}}  setImgURL={setImgURL} setModalImg={setModalImg}
                                    listEdo={listEdo}
                                /> : null
    const modNvoEvento = modalNvoEvento ?  <ModalNvoEvento abrirModal={modalNvoEvento} setAbrirModal={setModalNvoEvento}                                    
                                        buscarDatos={()=>setActualizar(!actualizar)} 
                                    /> : null      
    const modImg = modalImg ? <ModalQR abrirModal={modalImg} setAbrirModal={setModalImg}  imgURL={imgURL} titulo={titulo} />:null                                       

  return( 
  <div>
    {modImg}
    {modNvo}
    {modNvoEvento}
    {/* <Box display={"flex"} mt={1}>
      
    <Button variant="outlined" onClick={()=>setModalNvo(true)} style={{marginRight:"1rem"}}>Nuevo Registro</Button>
    </Box> */}
    {/* <Button variant="outlined" onClick={()=>setModalNvoEvento(true)}>Nuevo Evento</Button> */}
    {!cargando ?
        <TablaRegistro registro={registro} setRegistro={setRegistro} buscar={false} setImgURL={setImgURL}
            setModalImg={setModalImg} setTituloQR={setTitulo}  
            campoEdo={campoEdo} campoMuni={campoMuni} muni={Municipio}
        /> 
    :<Box><LinearProgress/></Box>}
  </div>);
};

export default Principal;