import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Polyline,
  InfoBox,Polygon, Marker,  
} from "@react-google-maps/api";
import { Typography, Box, Paper, Card } from "@material-ui/core";
import moment from "moment";
//import TablaUbicaciones from "./TablaUbicaciones";
const mapContainerStyle = {
  height: "500px",
  width: "100%", //"900px"
};

const center = { lat: 19.31248675518036, lng:-99.13242798283345};
const arreColores = [
  "#038545",
  "#EF8012",
  "#347AF4",
  "#FFC107",
  "#119A4F",
  "#B406D8",
  "#FFA318",
  "#DC4A3D",
  "#32CA81",
  "#F9B546",
  "#ED0808",
  "#0097A7",
  "#880E4F",
  "#4BAC7D",
  "#C52363",
  "#F57F06",
  "#7C594C",
  "#DBE772",
];

const MapaUnidSeg = ({listHexa, tamanio, libreriaG,listRango}) => {

  const [auxMarcador, setAuxMarcador] = useState(center);
  const [auxZoom, setAuxZoom] = useState(13);  
  const [auxColores, setAuxColores] = useState(arreColores);
  const [listInfoBox, setListInfoBox] = useState([]);
  const [listAuxColor, setListAuxColor] = useState([]); //arreglo de colores
  const[listHexaPolg, setListHexaPolg] = useState([]); 
  const[listAntena, setListAntena] = useState([]); 

  useEffect(() => {

    let axGeneralGps=[], axListAntena=[], axColor=[]
    listHexa.forEach((hexa)=>{
      if (hexa.Gps.length!==0) 
      { let axGps=[]
        hexa.Gps.forEach((coord,index)=>{
         
          const axpath = generaCoordenadaDinam(coord.lt, coord.ln);
          if (index===1) {
            setAuxMarcador(axpath)
          }
          axGps.push(axpath);
        })

        
        //console.log(axGps);
        
        axGeneralGps.push({coordenada:axGps})
      }
      if (hexa.Antena.length !== 0 ) 
      { 
        hexa.Antena.forEach((antena)=>{
          const axpath = generaCoordenadaDinam(antena.lat, antena.lon);
          axListAntena.push({coordenada:axpath, alias:antena.alias})
        })                
        const limite=hexa.Antena.length
        listRango.forEach((rango)=>{
          //console.log(rango,limite);
          if (limite >=rango.min && limite<=rango.max) {            
            const color=`#${rango.color.substring(3,rango.color.length)}`
            axColor.push(color )    
          }
        })  

      }
    })
    console.log(axColor);
    setAuxColores(axColor)
    setListAntena(axListAntena)
    setListHexaPolg(axGeneralGps)
  }, [listHexa]);

  const generarColor=(auxLimite)=>{
    let auxArregloColor = [];
    const limite = 10;
    if (auxLimite > limite) {
      for (let index = 0; index < auxLimite; index++) {
        let nivel = index % limite === 0 ? (index / limite === 1 ? 0 : limite - 1) : index % limite;          
        auxArregloColor.push({ color: arreColores[nivel], nivel: nivel + 1 });
      }
    } else {
      for (let index = 0; index <= 10; index++) {
        auxArregloColor.push({ color: arreColores[index], nivel: index + 1 });
      }
    }      
    setListAuxColor(auxArregloColor);
  }

  const formatoInfo2 = (info, valor) => {
    let auxArr = [];
    const txt = "Unidad " + info.usuPatrulla;
    const txt2 =
      "Ultima Actualización: " + moment(info.fecha).format("hh:mm:ss");
    let auxCoordenada = { lat: info.coordenada.lat, lng: info.coordenada.lng };
    auxArr = {
      coordenada: auxCoordenada,
      texto: txt,
      texto2: txt2,
      fecha: info.fecha,
      usuPatrulla: info.usuPatrulla,
      valor: valor,
    };
    return auxArr;
  };

  const generaCoordenadaDinam = (auxLat, auxLng) => {
    let coordenada = [];
    let aux1 = auxLat;
    aux1 = parseFloat(aux1);
    let aux2 = auxLng;
    aux2 = parseFloat(aux2);
    coordenada = { lat: aux1, lng: aux2 };
    return coordenada;
  };

  const onLoadPolygono = (marker) => {
    //console.log('Poligono: ', marker)
  };
  const image = { url: "marcadores/icon_repetidor.png" };

  const optionCuadrante = (color) => {
    let auxCol =color;
    const auxiliar = {
      fillColor: auxCol,
      fillOpacity: 0.15,
      strokeColor: auxCol,
      strokeOpacity: 0.2,
      strokeWeight: 1,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    };
    return auxiliar;
  };

  const obtenerColor = ( indice) => {
    let respuesta=""    
    let limite=10
    let nivel = indice % limite === 0 ? (indice / limite === 1 ? 0 : limite - 1) : indice % limite;                  
    respuesta={color:arreColores[nivel],nivel:nivel+1 } ;
        
    return(respuesta)
  }

  const lineaColor=(indice) => {
 let auxColor="#06B847"
    //console.log(auxColor);
    return{
    strokeColor: auxColor,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: auxColor,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,    
    zIndex: 1,}
  };

  const cerrarInfoTxt = (info) => {
    let auxArr = [];
    let listFiltrada = listInfoBox.filter(
      (txt) => txt.usuPatrulla !== info.usuPatrulla
    );
    auxArr = formatoInfo2(info, false);
    setListInfoBox([...listFiltrada, auxArr]);
    setListInfoBox(listFiltrada);
  };

  const optionInfoTxt = (valor) => {
    return { /* closeBoxURL: '',  */ enableEventPropagation: valor };
  };

  
  const formatoInfo=(info)=>{    
    let auxArr=[]
    const txt="Antena "+info.alias
    const txt2=""//"Ultima Actualización: "   
    //console.log(info);
    //let auxCoordenada={lat:info.coordenada.lat+0.0000500,lng:info.coordenada.lng}   
    let auxCoordenada={lat:info.coordenada.lat,lng:info.coordenada.lng}   
    auxArr={coordenada:auxCoordenada,texto:txt,texto2:txt2,fecha:info.fecha,alias:info.alias}
    //setListInfoBox(auxArr)    
    //console.log(auxArr);
    return auxArr
  }

  
  const guardarTxt=(info)=>{
    let listFiltrada=listInfoBox.filter((txt)=>txt.alias===info.alias)
    //console.log(listFiltrada);
    if ( listFiltrada.length === 0 ) 
    {      
      let auxArr=[]
      //console.log(info);     
      auxArr=formatoInfo(info)
      setListInfoBox([...listInfoBox,auxArr])
      //console.log(auxArr);
    }
  }

  return (
  <LoadScript
    googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" language="spanish" libraries={libreriaG}
  >
    <GoogleMap id="mapa-monit-alerta" center={auxMarcador} zoom={auxZoom}
      mapContainerStyle={tamanio ? tamanio : mapContainerStyle}
    >
      {listInfoBox.length !== 0 //infoWindow posicion de unidad
        ? listInfoBox.map((unidad, index) => {
          return (
          <InfoBox
            title={"Unidad " + unidad.usuPatrulla}
            key={index} icon={image}
            position={unidad.coordenada}                  
            onCloseClick={() => cerrarInfoTxt(unidad)}
            option={optionInfoTxt(unidad.valor)}
          >
            <Paper>
              <Box p={1}>
                <Typography variant="p">{unidad.texto}</Typography>
                <br />
                <Typography variant="p">{unidad.texto2}</Typography>
              </Box>
            </Paper>
          </InfoBox>
          );
        })
        : null}
      {listHexaPolg !== null && listHexaPolg.length > 0 //lista de poligonos de Cuadrante
        ? listHexaPolg.map((auxVia, index) => {
            //console.log(auxVia);
            return (
            <Polygon
              onLoad={onLoadPolygono}
              paths={auxVia.coordenada}
              options={optionCuadrante(auxColores[index])}
              key={index}
            />
            );
          })
      : null}

      {listHexaPolg.length !== 0 ? (
        listHexaPolg.map((aux,index)=>{
        return<Polyline
          //onLoad={onLoad}
          path={aux.coordenada}
          options={lineaColor(auxColores[index])}
        />
        })
        
      ) : null}       
       { listAntena.length!==0?    //marcadores posicion de unidad
        listAntena.map((antena,index)=>{
          return(
          <Marker  title={"Antena "+antena.alias} onClick={()=>guardarTxt(antena)}
            position={ antena.coordenada} icon= {image}     key={index}              
          />
          )
        })
        :null 
      }
    </GoogleMap>
  </LoadScript>
  );
};

export default MapaUnidSeg;