import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box,IconButton, LinearProgress, Paper,Table,
    TableBody, TableCell, TableContainer, TableHead,Select,MenuItem,
    TableRow, Tooltip, TablePagination,Grid,FormControl,InputLabel} from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import {ErrorGeneral, llamadaApiToken,llamaApiCargaToken} from '../funciones/ClienteAxios';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import PaginacionTab from "../layout/PaginacionTab";
import {numeroPag} from "../funciones/Paginacion";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import XLSX from 'xlsx';
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 200,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor:theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaRegistro({  registro, setRegistro, setImgURL , setModalImg, setTituloQR,  campoEdo, campoMuni,muni }) {
    const classes = useStyles();
    const source = axios.CancelToken.source(); 
    const history = useHistory();
    const tamMax = 150
    const idusu = localStorage.getItem('UsuId');   
    const auxDispV = localStorage.getItem("Dispvo") && localStorage.getItem("Dispvo").length >= 12 ? localStorage.getItem("Dispvo") : "451224452121";
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = useState([])
    const [listOrig, setListOrig] = useState([])    
    const [sinDatos, guardarSinDatos] = useState(true)        
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [numPagina, setNumPagina] = useState(0);
    const [listPagina,setListPagina]=useState([]);    
    const [Evento, setEvento] = useState("");    
    //const [cargando, setCargando] = useState(false);     
    const [listaExcel, setListaExcel] = useState([]);
    const seleccionar = (auxId, auxOrden) => {
        setRegistro(auxId)     
    }

    const llenarTabla = (muni) => {
        setEspera(true)            
        const data =  qs.stringify({
            Muni: muni.Id,             
            idDispositivo: auxDispV
        })                
        const url = "antena-list";

        function response(data) {
            
            if (data[0].Id !== -1) {
                guardarSinDatos(true)
                guardarDatos(data)
               // setListOrig(data)
                setEspera(false)
                numeroPag(data,tamMax,setTotalPaginas,setNumPagina,setListPagina)  
                let arrM=[]
                arrM.push(["#","Fecha Registro","Alias","Banda","Zona","Tecnologia","Operador","Chanel","Estado","Municipio","Dirección"])
                data.forEach((elemnt,index) => {
                    arrM.push([index+1,moment.utc(elemnt.Freg).format("YYYY-MM-DD HH:mm") ,elemnt.Alias, elemnt.BandaAlias, elemnt.ZonaAlias, elemnt.TecnoAlias,
                        elemnt.OperaAlias, elemnt.Chanel, elemnt.EdoNom, elemnt.MunNom, elemnt.Dir])                   
                });
                
                setListaExcel(arrM)
            } else {
                setListaExcel([])
                guardarSinDatos(false)
                setEspera(false)
            }
        }

        llamadaApiToken(data, url, response, ErrorGeneral, history, source);
    }

    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);        
       // console.log(listaExcel);
        const ws = XLSX.utils.aoa_to_sheet(listaExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista General.xlsx")
      };
    
    function generarFooter() {
        return (
        <TablePagination
            rowsPerPageOptions={false} style={{padding:0}}
            rowsPerPage={tamMax} count={datos.length}
            page={numPagina} lang='es'
            labelDisplayedRows={
                ({from, to, count}) => {
                    return '' + from + '-' + to + ' de ' + count
                }
            }
            onPageChange={onChangePagina}
            ActionsComponent={PaginacionTab}/>)
    }

    function onChangePagina(event, newPage){
        event.preventDefault();
        if (newPage ===0) 
        {
          setListPagina(datos.slice(0,tamMax))   
        }
        else
        {
          setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
        }
        setNumPagina(newPage)
    }

    const celda=(alinear,estilo,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
            {contenido}
        </TableCell>
        )
    }  

    function abrirModalQR(row){      
        setModalImg(true);  
        setImgURL(row.QrCode) 
        setTituloQR(`${row.Nom} ${row.Pat} ${row.Mat}`)
    }

    function Row(props) {
        const {row} = props;
        return (           
        <TableRow onClick={() => { seleccionar(row.Id, row) }}
            className={classes.tableRow} selected={registro?.Id === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >                                 
            {celda("center",{fontSize: 12 + 'px',width:70},moment.utc(row.Freg).format("YYYY-MM-DD HH:mm")
             ) }              
            {celda("left",{fontSize: 12 + 'px',width:100}, `${row.Alias} ` )}      
            {celda("center",{fontSize: 12 + 'px',width:75},row.BandaAlias )}          
            {celda("center",{fontSize: 12 + 'px',width:55}, row.ZonaAlias)} 
            {celda("center",{fontSize: 12 + 'px',width:55}, row.TecnoAlias)}       
            {celda("center",{fontSize: 12 + 'px',width:55}, row.OperaAlias)}    
            {celda("center",{fontSize: 12 + 'px',width:55}, row.Chanel)}                 
            {celda("center",{fontSize: 12 + 'px',width:75},row.EdoNom )}   
            {celda("center",{fontSize: 11 + 'px',width:80}, row.MunNom)}
            {celda("center",{fontSize: 11 + 'px',width:140}, row.Dir)}                                                                                    
         {/*    {celda("center",{fontSize: 11 + 'px',width:80}, row.Tel)}                      
 */}
        </TableRow>                      
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                                                
                    {celda("center",{},auxlista.length)}                     
                    <TableCell align="center" padding='none'>Alias</TableCell>          
                    <TableCell align="center" padding='none'>Banda</TableCell>
                    <TableCell align="center" padding='none'>Zona</TableCell>           
                    <TableCell align="center" padding='none'>Tecnologia</TableCell> 
                    <TableCell align="center" padding='none'>Operador</TableCell>      
                    <TableCell align="center" padding='none'>Chanel</TableCell>           
                    <TableCell align="center" padding='none'>Estado</TableCell>        
                    <TableCell align="center" padding='none'>Municipio</TableCell>    
                    <TableCell align="center" padding='none'>Dirección</TableCell>      
                    {/* <TableCell align="center" padding='none'>Teléfono</TableCell>      */}                                                                                                                  
                {/*     <TableCell align="center" padding='none'>Genero</TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length !== 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}            
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'>Alias</TableCell>         
                            <TableCell align="center" padding='none'>Banda</TableCell>                                                                           
                            <TableCell align="center" padding='none'>Zona</TableCell>                                                        
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="center"></TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Registros</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const onClickActualizar=()=>{        
        if (muni!=="") {
            llenarTabla(muni)    
        }
        
    }
    
    const paginacion = !espera && listPagina.length !== 0 && totalPaginas > 1 ? generarFooter() : null;
    const contenido = sinDatos && listPagina.length !== 0 ? tablaCompleta(listPagina) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
    <Paper style={{marginTop:"1rem"}}>
        <Box display="flex" flexDirection={"row"}>
        <Box style={{width:"16rem",marginTop:".7rem",marginLeft:"1rem"}}>
            {campoEdo}
        </Box>            
        <Box style={{width:"20rem",marginTop:".7rem",marginLeft:"1rem"}}>
            {campoMuni}
        </Box>            
        <Box style={{marginBottom:".5rem",marginTop:".5rem"}}>
            <Tooltip title={"Buscar"}> 
                <IconButton color="primary"  aria-label="add circle" 
                    component="span" onClick={onClickActualizar}>           
                    <SearchIcon/>        
                </IconButton>
            </Tooltip>
            {listaExcel.length!==0 ?<Tooltip title="Exportar a Excel">
                <IconButton onClick={exportarArch} size="small"                     
                    style={{marginLeft:"1rem"}} >            
                    <SaveIcon  color={ "primary"}  size="small" />            
                </IconButton>   
            </Tooltip>
            :null}
        </Box>            
        </Box>
        {paginacion}
        {tabla}
        
    </Paper>
    );
}