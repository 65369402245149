import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import { ToastContainer } from 'react-toastify';
import {generarTema} from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import Contenedor from './componentes/layout/Contenedor'
import Login from './componentes/auth/Login'
import MonitUnidSeg from './componentes/MonitoreoMaps/MonitUnidSeg';
import Principal from './componentes/principal/Principal';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [colorTema,setColorTema] = React.useState(false);
  React.useEffect(()=>{
    const auxColor= localStorage.getItem('ColorTheme');
    if ( auxColor===undefined || auxColor===null) 
    {
      localStorage.setItem('ColorTheme',colorTema);
      
    }
    else
    { 
      setColorTema(JSON.parse(auxColor))    
    }
  },[])

  return (
  <React.Fragment >
    <AuthProvider>
      <GeneralesProvider>   
        <Router>
          <ThemeProvider theme={generarTema(colorTema)}>
            <Contenedor colorTema={colorTema} setColorTema={setColorTema}>              
              <Switch>
                <Route exact path="/" component={Login}  />                                             
                <Route exact path="/bitacora" component={Principal}  />                 
                <Route exact path="/mapa" component={MonitUnidSeg}  /> 
                <Route path="*" component={  Login } />                                                   
              </Switch>              
            </Contenedor>
          </ThemeProvider>
          <ToastContainer autoClose={5000} closeOnClick/>
        </Router> 
      </GeneralesProvider>
    </AuthProvider>
  </React.Fragment>
  );
}

export default App;